import React, { useState } from "react";
import {
  TextField,
  Autocomplete,
  Button,
  MenuItem,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import axios from "axios";
import debounce from "lodash.debounce";

const HoroscopeForm = () => {
  const [citySuggestions, setCitySuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    birthDate: "",
    birthTime: "",
    city: "",
    queryType: "",
    latitude: "",
    longitude: "",
  });

  const [responseMessage, setResponseMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // State to track form submission
  const [showResponseBox, setShowResponseBox] = useState(false); // To control the response box visibility

  const fetchCitySuggestions = debounce(async (cityName) => {
    if (cityName.length >= 4) {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://geocode.maps.co/search?q=${cityName}&api_key=66faeb228b94b560880360qulfa5183`
        );
        setCitySuggestions(response.data);
      } catch (error) {
        console.error("Error fetching city suggestions:", error);
      } finally {
        setLoading(false);
      }
    } else {
      setCitySuggestions([]);
    }
  }, 300);

  const handleCityChange = (event, value) => {
    setFormData({ ...formData, city: value });
    if (value) {
      const selectedCity = citySuggestions.find(
        (city) => city.display_name === value
      );
      if (selectedCity) {
        setFormData({
          ...formData,
          latitude: selectedCity.lat,
          longitude: selectedCity.lon,
        });
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "city") {
      fetchCitySuggestions(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Start showing the loading spinner
    setShowResponseBox(false); // Hide response box while submitting

    try {
      const response = await axios.post(
        "http://34.42.249.153:4000/getHoroscope",
        formData
      );console.log("Response:", response);
      setResponseMessage(response.data.response);
      setOpenSnackbar(true);
      setShowResponseBox(true); // Show response box after receiving response
    } catch (error) {
      console.error("Error submitting form:", error);
      setResponseMessage("An error occurred while fetching the horoscope.");
      setOpenSnackbar(true);
    } finally {
      setIsSubmitting(false); // Hide the loading spinner after response
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div style={{ maxWidth: 500, margin: "auto", padding: 20 }}>
      <h1 className="text-3xl font-bold text-center mb-6">
        Get Your Horoscope 🌠
      </h1>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Birth Date"
          name="birthDate"
          value={formData.birthDate}
          onChange={handleInputChange}
          type="date"
          fullWidth
          margin="normal"
          required
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="Birth Time"
          name="birthTime"
          value={formData.birthTime}
          onChange={handleInputChange}
          type="time"
          fullWidth
          margin="normal"
          required
          InputLabelProps={{ shrink: true }}
        />

        <Autocomplete
          freeSolo
          options={citySuggestions.map((option) => option.display_name)}
          onInputChange={(event, newValue) => {
            handleInputChange({ target: { name: "city", value: newValue } });
          }}
          onChange={handleCityChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label="City"
              name="city"
              fullWidth
              margin="normal"
              required
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />

        <TextField
          select
          label="Select a Query Type"
          name="queryType"
          value={formData.queryType}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          required
        >
          <MenuItem value="health">Know about health</MenuItem>
          <MenuItem value="career">Know about career</MenuItem>
          <MenuItem value="married_life">Know about married life</MenuItem>
          <MenuItem value="wealth">Know about wealth</MenuItem>
        </TextField>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          style={{ marginTop: 16 }}
          disabled={isSubmitting} // Disable the button while submitting
        >
          {isSubmitting ? <CircularProgress size={24} /> : "Submit"}
        </Button>

        {/* Snackbar with scrollable message */}
        <Snackbar
          open={openSnackbar}
          onClose={handleCloseSnackbar}
          autoHideDuration={300000}
          message={
            <div
              style={{
                maxHeight: "400px", // Limit the height for mobile screens
                overflowY: "auto", // Enable vertical scrolling
                padding: "10px", // Padding for readability
                whiteSpace: "pre-wrap", // Keep line breaks and spacing
                wordWrap: "break-word", // Handle long words
              }}
            >
              {responseMessage}
            </div>
          }
        />
      </form>

      {/* Response Box */}
    </div>
  );
};

export default HoroscopeForm;

